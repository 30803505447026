"use client";
import React from "react";
import Image from "next/image";
export default function ClientLogos() {
  return (
    <section className="py-20 bg-gray-50 dark:bg-gray-900">
      <div className="container mx-auto px-4">
        {/* Heading */}
        <h2 className="text-center text-4xl md:text-5xl font-bold text-gray-900 dark:text-white mb-4">
          We have helped job seekers land their dream jobs at
        </h2>
        <p className="text-center text-lg text-gray-600 dark:text-gray-300 mb-12">
          Trusted by the world’s most innovative teams
        </p>

        {/* Logo Marquee Container */}
        <div className="relative overflow-hidden">
          {/* Gradient Fade Effect */}
          <div
            className="absolute inset-y-0 left-0 w-32 bg-gradient-to-r from-gray-50 dark:from-gray-900 to-transparent z-10"
            aria-hidden="true"
          ></div>
          <div
            className="absolute inset-y-0 right-0 w-32 bg-gradient-to-l from-gray-50 dark:from-gray-900 to-transparent z-10"
            aria-hidden="true"
          ></div>

          {/* Logo Marquee */}
          <div className="flex space-x-8 animate-marquee whitespace-nowrap">
            {/* First Set of Logos */}
            {[
              "https://www.logo.wine/a/logo/YouTube/YouTube-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Wikipedia/Wikipedia-Logo.wine.svg",
              "https://www.logo.wine/a/logo/JPMorgan_Chase/JPMorgan_Chase-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Amazon_(company)/Amazon_(company)-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Google/Google-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Apple_Inc./Apple_Inc.-Logo.wine.svg"
            ].map((logo, index) => (
              <Image
                width={140}
                height={30}
                key={index}
                className="h-16 md:h-20 w-auto opacity-80 hover:opacity-100 transition-opacity"
                src={logo}
                alt="Client Logo"
              />
            ))}

            {/* Second Set of Logos (Duplicated for seamless scrolling) */}
            {[
              "https://www.logo.wine/a/logo/YouTube/YouTube-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Wikipedia/Wikipedia-Logo.wine.svg",
              "https://www.logo.wine/a/logo/JPMorgan_Chase/JPMorgan_Chase-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Amazon_(company)/Amazon_(company)-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Google/Google-Logo.wine.svg",
              "https://www.logo.wine/a/logo/Apple_Inc./Apple_Inc.-Logo.wine.svg"
            ].map((logo, index) => (
              <Image
                width={140}
                height={30}
                key={index + 6} // Ensure unique keys
                className="h-16 md:h-20 w-auto opacity-80 hover:opacity-100 transition-opacity"
                src={logo}
                alt="Client Logo"
              />
            ))}
          </div>
        </div>
      </div>

      {/* Marquee Animation CSS */}
      <style jsx>{`
          @keyframes marquee {
              0% {
                  transform: translateX(0);
              }
              100% {
                  transform: translateX(-50%);
              }
          }

          .animate-marquee {
              animation: marquee 20s linear infinite;
          }
      `}</style>
    </section>
  );
}