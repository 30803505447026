"use client";
import React, { useRef } from "react";
import { ChevronLeft, ChevronRight, Quote, Star } from "lucide-react";
import { motion } from "framer-motion";
import Image from "next/image";
const Testimonials = () => {

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const testimonials = [
    {
      name: "Sarah Chen",
      role: "Software Engineer",
      company: "Oracle",
      image: "/images/testimonials/avatar/girl.png",
      quote: "This AI platform revolutionized how I prepare for technical interviews. It's been a game-changer in landing offers from top tech companies!",
      rating: 5
    },
    {
      name: "Michael Rodriguez",
      role: "Data Scientist",
      company: "Meta",
      image: "/images/testimonials/avatar/boy.png",
      quote: "The real-time feedback on my coding and problem-solving skills was invaluable. I secured my dream job thanks to MockMate!",
      rating: 5
    },
    {
      name: "Emily Thompson",
      role: "Frontend Developer",
      company: "Amazon",
      image: "/images/testimonials/avatar/girl.png",
      quote: "It's like having a personal coding coach. My interview confidence has skyrocketed!",
      rating: 5
    },
    {
      name: "David Kim",
      role: "DevOps Engineer",
      company: "Apple",
      image: "/images/testimonials/avatar/boy.png",
      quote: "The detailed analytics helped me pinpoint my weak areas. The improvement in my technical skills has been phenomenal.",
      rating: 5
    },
    {
      name: "Lisa Patel",
      role: "Web Developer",
      company: "Google",
      image: "/images/testimonials/avatar/girl.png",
      quote: "The platform's efficiency and personalized feedback exceeded my expectations. Highly recommended for any tech professional!",
      rating: 5
    }
  ];


  const scroll = (direction: "right" | "left") => {
    const container = scrollContainerRef.current;
    if (container) {
      const scrollAmount = container.offsetWidth * 0.8;
      const newScrollPosition = container.scrollLeft + (direction === "right" ? scrollAmount : -scrollAmount);

      container.scrollTo({
        left: newScrollPosition,
        behavior: "smooth"
      });
    }
  };

  const renderStars = (rating: number) => {
    return [...Array(5)].map((_, index) => (
      <Star
        key={index}
        className={`w-4 h-4 ${
          index < rating
            ? "text-indigo-500 fill-indigo-500"
            : "text-gray-300"
        }`}
      />
    ));
  };

  return (
    <div className=" p-4 sm:p-8" id="testimonials">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto mb-4 sm:mb-8"
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
            Success Stories
            <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400" />
          </h1>
        </motion.div>

        <div className="relative">
          <button
            onClick={() => scroll("left")}
            className="absolute left-0 top-1/2 -translate-y-1/2 -translate-x-4 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-white hover:shadow-xl transition-all duration-300"
          >
            <ChevronLeft className="w-6 h-6 text-indigo-600" />
          </button>

          <button
            onClick={() => scroll("right")}
            className="absolute right-0 top-1/2 -translate-y-1/2 translate-x-4 z-10 bg-white/90 p-2 rounded-full shadow-lg hover:bg-white hover:shadow-xl transition-all duration-300"
          >
            <ChevronRight className="w-6 h-6 text-indigo-600" />
          </button>

          <div
            ref={scrollContainerRef}
            className="flex overflow-x-hidden scroll-smooth gap-4 sm:gap-8 py-4 px-2"
          >
            {testimonials.map((testimonial, index) => (
              <div
                key={index}
                className="flex-none w-full md:w-[calc(33.333%-1.33rem)] bg-gradient-to-br from-indigo-100 to-purple-100 rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 shadow-xl hover:shadow-2xl transition-all duration-300 relative"
              >
                <Quote className="absolute top-4 right-4 w-8 h-8 text-indigo-200" />

                <div className="flex items-center space-x-4 mb-6">
                  <Image
                    width={64}
                    height={64}
                    src={testimonial.image}
                    alt={testimonial.name}
                    className="w-16 h-16 rounded-full object-cover"
                  />
                  <div>
                    <h3 className="text-xl text-indigo-950">
                      {testimonial.name}
                    </h3>
                    <p className="text-m text-indigo-800">
                      {testimonial.role}
                    </p>
                    <p className="text-m text-indigo-600">
                      {testimonial.company}
                    </p>
                  </div>
                </div>

                <div className="flex mb-4">
                  {renderStars(testimonial.rating)}
                </div>

                <p className="text-indigo-800 leading-relaxed">
                  "{testimonial.quote}"
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Testimonials;

