"use client";
import React, { useEffect, useState } from "react";
import Image from "next/image";
import "../CSS/styles.css";
import image1 from "../../../assets/images/image1.png";
import image2 from "../../../assets/images/image2.png";
import image3 from "../../../assets/images/image3.png";

export const Hero: React.FC = ({}) => {
  const images = [image1, image2, image3];

  // State to keep track of the current image index
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // useEffect hook to change the image every 4 seconds
  useEffect(() => {
    // Function to update the current image index
    const updateImageIndex = () => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    };


    const intervalId = setInterval(updateImageIndex, 4000);

    return () => clearInterval(intervalId);
  }, [images.length]);


  return (
    <>
      <section
        id="home"
        className="text-class relative z-10 overflow-hidden  pb-0 pt-[90px] dark:bg-gray-dark  md:pt-[120px] xl:pb-[160px] xl:pt-[150px] 2xl:pb-[0px] 2xl:pt-36"
      >
        <div
          className="container mx-auto px-6 lg:px-12 flex flex-col-reverse lg:flex-row items-center gap-12"
          data-aos="fade-up"
          data-aos-duration="700"
        >
          {/* Left Content */}
          <div className="w-full lg:w-1/2 text-center lg:text-left">
            {/* Badge */}
            {/* <div
              className="inline-block bg-blue-100 text-blue-700 dark:bg-blue-900 dark:text-blue-300 text-sm font-medium px-4 py-2 rounded-full shadow-md">
              First choice of Talent Acquisition, Hiring Managers, and Candidates
            </div> */}

            {/* Heading */}
            <h1 className="mt-6 text-4xl font-extrabold tracking-normal text-gray-900 dark:text-white sm:text-5xl">
              Experience a Revolutionary Approach to Interview Preparation with
              <span className="text-purple-600 dark:text-purple-400"> MockMate</span>
            </h1>

            {/* Subtext */}
            <p className="mt-4 text-lg font-normal text-gray-500 lg:text-xl dark:text-gray-400">
              Transforming Interviews with AI: Your Smart Path to Success.
            </p>

            {/* Call-to-Actions */}
            <div className="mt-6 flex flex-col sm:flex-row gap-4 justify-center lg:justify-start">
              <a
                data-attr={"mockmate-sign-up"}
                href="https://mockmate.althire.ai/signup"
                className="inline-block px-6 py-3 text-lg font-semibold text-white rounded-lg shadow-md transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-indigo-600 to-purple-600 hover:from-indigo-700 hover:to-purple-700"
              >
                Try MockMate Now
              </a>
            </div>
          </div>

          {/* Right Image */}
          <div className="w-full lg:w-1/2">
            <div className="w-full h-96 max-w-lg mx-auto overflow-hidden">
              <Image
                src={images[currentImageIndex]}
                alt="Questions"
                className="w-full object-cover rounded-lg shadow-lg"
                loading={"lazy"}
              />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;

