'use client'
import React from 'react';
import {motion} from 'framer-motion';

const JobRolesGrid = () => {
  const jobRoles = [
    { title: "Product / Project Management", available: true },
    { title: "Data & Analytics", available: true },
    { title: "Software Development and Engineering", available: true },
    { title: "Cloud Computing", available: true },
    { title: "Sales and Marketing", available: true },
    { title: "Human Resources (HR)", available: true },
    { title: "Finance and Accounting", available: true },
    { title: "Customer Service and Support", available: true },
    { title: "More to Follow...", available: true },
  ];

  const handleCardClick = () => {
    window.location.href = "https://mockmate.althire.ai/signup";
  };

  return (
    <div className="p-4 sm:p-8">
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="max-w-7xl mx-auto mb-4 sm:mb-8"
        >
          <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
            Choose Your Role
            <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400" />
          </h1>
          <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-600">
            Use one of our off-the-shelf templates or craft your own interview.
          </p>
        </motion.div>

        <div className="grid grid-cols-3 gap-4 sm:gap-8">
          {jobRoles.map((role, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ delay: index * 0.1 }}
              onClick={handleCardClick}
              className="relative rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 flex flex-col items-center justify-center
                bg-gradient-to-br from-indigo-100 to-purple-100
                transform hover:-translate-y-2 sm:hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-md sm:shadow-xl
                cursor-pointer group overflow-hidden"
            >
              {/* Background Gradient Animation */}
              <div
                className="absolute inset-0 bg-gradient-to-r from-purple-50 to-indigo-50 opacity-0
                  group-hover:opacity-100 transition-opacity duration-300"
              />

              <div className="relative z-10 flex flex-col items-center gap-2">
                <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-center text-indigo-950">
                  {role.title}
                </h3>
                {!role.available && (
                  <span className="ml-2 inline-block px-2 py-1 text-xs sm:text-sm font-semibold text-purple-600 bg-purple-100 rounded-full animate-pulse">
                    Coming Soon
                  </span>
                )}
              </div>
            </motion.div>
          ))}
        </div>

        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 1 }}
          className="mt-12 sm:mt-16 text-center"
        >
          <button
            onClick={handleCardClick}
            className="px-8 py-4 text-lg sm:text-xl font-semibold rounded-xl
              bg-gradient-to-r from-indigo-600 to-purple-600 text-white
              transform transition-all duration-300
              hover:from-indigo-700 hover:to-purple-700 hover:scale-105 hover:-translate-y-1
              shadow-md hover:shadow-xl"
          >
            Or, upload your Job Description to create your own interview
          </button>
        </motion.div>
      </div>
    </div>
  );
};

export default JobRolesGrid;


// import React from 'react';
// import { motion } from 'framer-motion';

// const JobRolesGrid = () => {
//   const jobRoles = [
//     { title: "Product / Project Management", available: true },
//     { title: "Data & Analytics", available: true },
//     { title: "Software Development and Engineering", available: true },
//     { title: "Cloud Computing", available: true },
//     { title: "Sales and Marketing", available: true },
//     { title: "Human Resources (HR)", available: true },
//     { title: "Finance and Accounting", available: true },
//     { title: "Customer Service and Support", available: true },
//     { title: "More to Follow...", available: true },
//   ];

//   const handleCardClick = () => {
//     window.location.href = "/signup";
//   };

//   return (
//     <div className="p-4 sm:p-8">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="max-w-7xl mx-auto mb-4 sm:mb-8"
//         >
//           <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
//             Choose Your Role
//             <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400" />
//           </h1>
//           <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-600">
//             Use one of our off-the-shelf templates or craft your own interview.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-3 gap-4 sm:gap-8">
//           {jobRoles.map((role, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: index * 0.1 }}
//               onClick={handleCardClick}
//               className="relative rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 flex flex-col items-center justify-center
//                 bg-gradient-to-br from-indigo-100 to-purple-100
//                 transform hover:-translate-y-2 sm:hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-md sm:shadow-xl
//                 cursor-pointer"
//             >
//               <div className="flex flex-col items-center gap-2">
//                 <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-center text-indigo-950">
//                   {role.title}
//                 </h3>
//                 {!role.available && (
//                   <span className="ml-2 inline-block px-2 py-1 text-xs sm:text-sm font-semibold text-purple-600 bg-purple-100 rounded-full animate-pulse">
//                     Coming Soon
//                   </span>
//                 )}
//               </div>
//             </motion.div>
//           ))}
//         </div>

//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           transition={{ delay: 1 }}
//           className="mt-12 sm:mt-16 text-center"
//         >
//           <button
//             onClick={handleCardClick}
//             className="px-8 py-4 text-lg sm:text-xl font-semibold rounded-xl
//               bg-gradient-to-r from-indigo-600 to-purple-600 text-white
//               transform transition-all duration-300
//               hover:from-indigo-700 hover:to-purple-700 hover:scale-105 hover:-translate-y-1
//               shadow-md hover:shadow-xl"
//           >
//             Or, upload your Job Description to create your own interview
//           </button>
//         </motion.div>
//       </div>
//     </div>
//   );
// };

// export default JobRolesGrid;


// import React from 'react';
// import { motion } from 'framer-motion';

// const JobRolesGrid = () => {
//   const jobRoles = [
//     { title: "Product / Project Management", available: true },
//     { title: "Data & Analytics", available: true },
//     { title: "Software Development and Engineering", available: true },
//     { title: "Cloud Computing", available: true },
//     { title: "Sales and Marketing", available: true },
//     { title: "Human Resources (HR)", available: true },
//     { title: "Finance and Accounting", available: true },
//     { title: "Customer Service and Support", available: true },
//     { title: "More to Follow...", available: true },
//   ];

//   const handleCardClick = () => {
//     window.location.href = "/signup";
//   };

//   return (
//     <div className=" p-4 sm:p-8">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="max-w-7xl mx-auto mb-4 sm:mb-8"
//         >
//           <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
//             Choose Your Role
//             <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400" />
//           </h1>
//           <p className="mt-4 text-lg sm:text-xl md:text-2xl text-gray-600">
//             Use one of our off-the-shelf templates or craft your own interview.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-3 gap-4 sm:gap-8">
//           {jobRoles.map((role, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: index * 0.1 }}
//               onClick={handleCardClick}
//               className="relative rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 flex flex-col items-center justify-center
//                 bg-gradient-to-br from-indigo-100 to-purple-100
//                 transform hover:-translate-y-2 sm:hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-md sm:shadow-xl
//                 cursor-pointer"
//             >
//               <div className="flex flex-col items-center gap-2">
//                 <h3 className="text-lg sm:text-xl md:text-2xl font-bold text-center text-indigo-950">
//                   {role.title}
//                 </h3>

//                 {!role.available && (
//                   <span className="ml-2 inline-block px-2 py-1 text-xs sm:text-sm font-semibold text-purple-600 bg-purple-100 rounded-full animate-pulse">
//                     Coming Soon
//                   </span>
//                 )}
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobRolesGrid;


// import React from 'react';
// import { motion } from 'framer-motion';

// const JobRolesGrid = () => {
//   const jobRoles = [
//     { title: "Product / Project Management", available: true },
//     { title: "Data & Analytics", available: true },
//     { title: "Software Development and Engineering", available: true },
//     { title: "Cloud Computing", available: true },
//     { title: "Sales and Marketing", available: true },
//     { title: "Human Resources(HR)", available: true },
//     { title: "Finance and Accounting", available: true },
//     { title: "Customer Service and Support", available: true },
//     { title: "More to Follow...", available: true },
//   ];

//   const handleCardClick = () => {
//     window.location.href = "/signup";
//   };

//   return (
//     <div className="min-h-screen p-8">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="max-w-7xl mx-auto mb-16"
//         >
//           <h1 className="text-6xl font-bold text-indigo-950 flex items-center">
//             Choose Your Role
//             <span className="ml-6 h-1 flex-grow bg-purple-400" />
//           </h1><br/>
//           <p className="text-2xl text-gray-600">
//           Use one of our off-the-shelf templates or craft your own interview.
//           </p>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           {jobRoles.map((role, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: index * 0.1 }}
//               onClick={handleCardClick}
//               className="relative rounded-2xl p-8 flex flex-col items-center justify-center
//                 bg-gradient-to-br from-indigo-100 to-purple-100
//                 transform hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-xl
//                 cursor-pointer min-h-[150px]"
//             >
//               <div className="flex flex-col items-center gap-2">
//                 <h3 className="text-2xl font-bold text-center text-indigo-950">
//                   {role.title}
//                 </h3>
                
//                 {!role.available && (
//                   <span className="ml-2 inline-block px-3 py-1 text-xs font-semibold text-purple-600 bg-purple-100 rounded-full animate-pulse">
//                     Coming Soon
//                   </span>
//                 )}
//               </div>
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobRolesGrid;


// import React, { useState } from 'react';
// import { motion } from 'framer-motion';

// const JobRolesGrid = () => {
//   const [showModal, setShowModal] = useState(false);
//   const [selectedRole, setSelectedRole] = useState(null);

//   const jobRoles = [
//     { title: "Data Engineer", available: true },
//     { title: "Data Scientist", available: true },
//     { title: "Program Manager", available: true },
//     { title: "Product Manager", available: true },
//     { title: "Call Centre Executive", available: false },
//     { title: "Financial Analyst", available: true },
//     { title: "Chartered Accountant", available: false },
//     { title: "Digital Marketing Specialist", available: true },
//     { title: "Medical Professional", available: false },
//   ];

//   const handleCardClick = (role) => {
//     if (role.available) {
//       window.location.href = "/signin"; // Or use router.push('/subscribe') for Next.js
//     } else {
//       setSelectedRole(role);
//       setShowModal(true);
//     }
//   };

//   return (
//     <div className="min-h-screen p-8">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="max-w-7xl mx-auto mb-16"
//         >
//           <h1 className="text-6xl font-bold text-indigo-950 flex items-center">
//             Choose Your Role
//             <span className="ml-6 h-1 flex-grow bg-purple-400" />
//           </h1>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           {jobRoles.map((role, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: index * 0.1 }}
//               onClick={() => handleCardClick(role)}
//               className="relative rounded-2xl p-8 flex flex-col items-center justify-center
//                 bg-gradient-to-br from-indigo-100 to-purple-100
//                 transform hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-xl
//                 cursor-pointer min-h-[150px]"
//             >
//               <div className="flex flex-col items-center gap-2">
//                 <h3 className="text-2xl font-bold text-center text-indigo-950">
//                   {role.title}
//                 </h3>
                
//                 {!role.available && (
//                   <span className="ml-2 inline-block px-3 py-1 text-xs font-semibold text-purple-600 bg-purple-100 rounded-full animate-pulse">
//                     Coming Soon
//                   </span>
//                 )}
//               </div>
//             </motion.div>
//           ))}
//         </div>

//         {/* Modal for Coming Soon roles */}
//         {showModal && (
//           <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
//             <motion.div 
//               initial={{ scale: 0.5, opacity: 0 }}
//               animate={{ scale: 1, opacity: 1 }}
//               className="bg-white rounded-2xl p-8 max-w-md mx-4"
//             >
//               <h3 className="text-2xl font-bold text-indigo-950 mb-4">
//                 Coming Soon!
//               </h3>
//               <p className="text-gray-600 mb-6">
//                 {selectedRole?.title} interviews are coming soon! We're working hard to bring you the best interview preparation experience for this role.
//               </p>
//               <div className="flex justify-end">
//                 <button
//                   onClick={() => setShowModal(false)}
//                   className="px-6 py-2 bg-gradient-to-r from-indigo-600 to-purple-600 text-white rounded-lg hover:from-indigo-700 hover:to-purple-700 transition-all duration-200"
//                 >
//                   Close
//                 </button>
//               </div>
//             </motion.div>
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default JobRolesGrid;


// import React from 'react';
// import { motion } from 'framer-motion';

// const JobRolesGrid = () => {
//   const jobRoles = [
//     { title: "Data Engineer", available: true },
//     { title: "Data Scientist", available: true },
//     { title: "Program Manager", available: true },
//     { title: "Product Manager", available: true },
//     { title: "Call Centre Executive", available: false },
//     { title: "Financial Analyst", available: true },
//     { title: "Chartered Accountant", available: false },
//     { title: "Digital Marketing Specialist", available: true },
//     { title: "Medical Professional", available: false },
//   ];

//   const handleCardClick = (available) => {
//     if (available) {
//       // Use the same redirect logic as your "Subscribe Now" button
//       window.location.href = "/subscribe"; // Replace with your actual subscription URL
//     }
//   };

//   return (
//     <div className="min-h-screen p-8">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{ opacity: 0, y: 20 }}
//           animate={{ opacity: 1, y: 0 }}
//           className="max-w-7xl mx-auto mb-16"
//         >
//           <h1 className="text-6xl font-bold text-indigo-950 flex items-center">
//             Choose Your Role
//             <span className="ml-6 h-1 flex-grow bg-purple-400" />
//           </h1>
//         </motion.div>

//         <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
//           {jobRoles.map((role, index) => (
//             <motion.div
//               key={index}
//               initial={{ opacity: 0, y: 20 }}
//               animate={{ opacity: 1, y: 0 }}
//               transition={{ delay: index * 0.1 }}
//               onClick={() => role.available && handleCardClick(role.available)}
//               className={`
//                 relative rounded-2xl p-8 flex flex-col items-center justify-center
//                 bg-gradient-to-br ${role.available 
//                   ? 'from-indigo-100 to-purple-100 hover:-translate-y-3 cursor-pointer' 
//                   : 'from-gray-100 to-gray-200 cursor-not-allowed'
//                 }
//                 transform transition-all duration-300 ease-in-out shadow-xl
//                 min-h-[150px]
//               `}
//             >
//               <h3 className={`text-2xl font-bold text-center ${
//                 role.available ? 'text-indigo-950' : 'text-gray-500'
//               }`}>
//                 {role.title}
//               </h3>
              
//               {!role.available && (
//                 <div className="absolute bottom-4 left-0 right-0 flex justify-center">
//                   <span className="bg-gray-400 text-white text-sm font-semibold px-4 py-1 rounded-full">
//                     Coming Soon
//                   </span>
//                 </div>
//               )}
//             </motion.div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default JobRolesGrid;