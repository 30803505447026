'use client'
import React from 'react';
import {Check, X} from 'lucide-react';
import {motion} from 'framer-motion';

interface Tier {
  name: string;
  price: string;
  period: string;
  description: string;
  recommended: boolean;
  ctaText: string;
  features: {
    name: string;
    included: boolean;
  }[];
  hidePrice?: boolean; // Made optional
}

const PricingCards = () => {
  const pricingTiers: Tier[] = [
    {
      name: "Pay as you go",
      price: "$0",
      period: "to start",
      hidePrice: false, // Added this line
      description: "Get your first 3 interviews free, then pay per use.",
      recommended: false,
      ctaText: "Start Free",
      features: [
        { name: "First 3 interviews completely free", included: true },
        { name: "$1 per interview for over-the-shelf interviews (first 30 days)", included: true },
        { name: "$5 per interview for over-the-shelf interviews  or $7 per interview for your own curated interviews (after 30 days)", included: true },
      ],
    },
    {
      name: "Monthly",
      price: "$25",
      period: "/month",
      hidePrice: false, // Added this line
      description: "50% Early bird discount (Regular $50/month).",
      recommended: true,
      ctaText: "Subscribe Now",
      features: [
        { name: "All Pay as you go features included", included: true },
        { name: "Unlimited access to AI-based resume review (coming soon)", included: true },
        { name: "Review one report monthly with an executive coach (coming soon)", included: true },
        { name: "Identify strengths and growth areas", included: true },
      ],
    },
    {
      name: "Enterprise",
      price: "💎",
      period: "",
      hidePrice: false,
      description: "For pricing details, contact sales.",
      recommended: false,
      ctaText: "Contact Sales",
      features: [
        { name: "Unlimited interviews", included: true },
        { name: "Unlimited access to AI-based resume review (coming soon)", included: true },
        { name: "Monthly executive coach session for all candidates (coming soon)", included: true },
        { name: "Identify strengths and growth areas", included: true },
      ],
    },
  ];

  return (
    <div className="min-h-screen p-8" id='pricing'>
      <div className="max-w-6xl mx-auto">
        <motion.div
          initial={{opacity: 0, y: 20}}
          animate={{opacity: 1, y: 0}}
          className="max-w-7xl mx-auto mb-12"
        >
          <h1 className="text-6xl text-class font-bold text-indigo-950 flex items-center">
            Simple, transparent pricing
            <span className="ml-6 h-1 flex-grow bg-purple-400"/>
          </h1>
        </motion.div>

        <div className="grid md:grid-cols-3 gap-8">
          {pricingTiers.map((tier) => (
            <div
              key={tier.name}
              className="relative rounded-2xl p-8 flex flex-col bg-gradient-to-br from-indigo-100 to-purple-100 transform hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-xl"
            >
              {tier.recommended && (
                <div className="text-class absolute -top-4 left-1/2 transform -translate-x-1/2">
                  <span className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm font-semibold px-4 py-1 rounded-full">
                    Recommended
                  </span>
                </div>
              )}

              <div className="space-y-4">
                <h3 className="text-4xl text-class font-bold text-indigo-950">
                  {tier.name}
                </h3>
                <div className="flex items-baseline">
                  {!tier.hidePrice && (
                    <>
                      <span className="text-5xl text-class font-bold text-indigo-950">
                        {tier.price}
                      </span>
                      <span className="text-lg text-class ml-2 text-indigo-700">
                        {tier.period}
                      </span>
                    </>
                  )}
                </div>
                <p className="text-class text-xl text-indigo-800">
                  {tier.description}
                </p>
              </div>

              <div className="mt-8 space-y-4 flex-grow">
                {tier.features.map((feature, featureIndex) => (
                  <div 
                    key={featureIndex}
                    className="flex items-center space-x-3"
                  >
                    {feature.included ? (
                      <Check className="w-5 h-5 text-indigo-600 flex-shrink-0" />
                    ) : (
                      <X className="w-5 h-5 text-indigo-300 flex-shrink-0" />
                    )}
                    <span className={`
                      ${feature.included 
                        ? 'text-class text-indigo-800' 
                        : 'text-indigo-400'
                      }
                    `}>
                      {feature.name}
                    </span>
                  </div>
                ))}
              </div>

              <button className="text-class w-full mt-8 px-4 py-3 rounded-lg font-semibold transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700">
                {tier.ctaText}
              </button>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCards;


// import React from 'react';
// import {Check, X} from 'lucide-react';
// import {motion, MotionValue, useScroll, useSpring, useTransform} from "framer-motion";

// const PricingCards = () => {
//   const pricingTiers = [
//     {
//       name: "Pay as you go",
//       price: "$0",
//       period: "to start",
//       description: "Get your first 3 interviews free, then pay per use.",
//       recommended: false,
//       ctaText: "Start Free",
//       features: [
//         { name: "First 3 interviews completely free", included: true },
//         { name: "$1 per interview for over-the-shelf interviews (first 30 days)", included: true },
//         { name: "$5 per interview for over-the-shelf interviews  or $7 per interview for your own curated interviews (after 30 days)", included: true },
//       ]
//     },
//     {
//       name: "Monthly",
//       price: "$25",
//       period: "/month",
//       description: "50% Early bird discount (Regular $50/month).",
//       recommended: true,
//       ctaText: "Subscribe Now",
//       features: [
//         { name: "All Pay as you go features included", included: true },
//         { name: "Unlimited access to AI-based resume review (coming soon)", included: true },
//         { name: "Review one report monthly with an executive coach (coming soon)", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     },
//     {
//       name: "Enterprise",
//       price: "💎",
//       period: "",
      
//       // hidePrice: true, // Added this flag to handle price display
//       description: "For pricing details, contact sales.",
//       recommended: false,
//       ctaText: "Contact Sales",
//       features: [
//         { name: "Unlimited interviews", included: true },
//         { name: "Unlimited access to AI-based resume review (coming soon)", included: true },
//         { name: "Monthly executive coach session for all candidates (coming soon)", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     }
//   ];

//   return (
//     <div className="min-h-screen p-8" id='pricing'>
//       <div className="max-w-6xl mx-auto">
//       <motion.div
//                     initial={{opacity: 0, y: 20}}
//                     animate={{opacity: 1, y: 0}}
//                     className="max-w-7xl mx-auto mb-12"
//                 >
//                     <h1 className="text-6xl text-class font-bold text-indigo-950 flex items-center">
//                     Simple, transparent pricing
//                         <span className="ml-6 h-1 flex-grow bg-purple-400"/>
//                     </h1>
                    
//                 {/* <p className="text-xl text-indigo-900">Start with 3 free interviews</p> */}
//                 </motion.div>
//         {/* <div className="text-center mb-16">
//           <h2 className="text-4xl font-bold text-indigo-950 mb-4">Simple, transparent pricing</h2>
//           <p className="text-xl text-indigo-900">Start with 3 free interviews</p>
//         </div> */}
        
//         <div className="grid md:grid-cols-3 gap-8">
//           {pricingTiers.map((tier) => (
//             <div
//               key={tier.name}
//               className="relative rounded-2xl p-8 flex flex-col bg-gradient-to-br from-indigo-100 to-purple-100 transform hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-xl"
//             >
//               {tier.recommended && (
//                 <div className="text-class absolute -top-4 left-1/2 transform -translate-x-1/2">
//                   <span className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm font-semibold px-4 py-1 rounded-full">
//                     Recommended
//                   </span>
//                 </div>
//               )}

//               <div className="space-y-4">
//                 <h3 className="text-4xl text-class font-bold text-indigo-950">
//                   {tier.name}
//                 </h3>
//                 <div className="flex items-baseline">
//                   {!tier.hidePrice && (
//                     <>
//                       <span className="text-5xl text-class font-bold text-indigo-950">
//                         {tier.price}
//                       </span>
//                       <span className="text-lg text-class ml-2 text-indigo-700">
//                         {tier.period}
//                       </span>
//                     </>
//                   )}
//                 </div>
//                 <p className="text-class text-xl text-indigo-800">
//                   {tier.description}
//                 </p>
//               </div>

//               <div className="mt-8 space-y-4 flex-grow">
//                 {tier.features.map((feature, featureIndex) => (
//                   <div 
//                     key={featureIndex}
//                     className="flex items-center space-x-3"
//                   >
//                     {feature.included ? (
//                       <Check className="w-5 h-5 text-indigo-600 flex-shrink-0" />
//                     ) : (
//                       <X className="w-5 h-5 text-indigo-300 flex-shrink-0" />
//                     )}
//                     <span className={`
//                       ${feature.included 
//                         ? 'text-class text-indigo-800' 
//                         : 'text-indigo-400'
//                       }
//                     `}>
//                       {feature.name}
//                     </span>
//                   </div>
//                 ))}
//               </div>

//               <button className="text-class w-full mt-8 px-4 py-3 rounded-lg font-semibold transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700">
//                 {tier.ctaText}
//               </button>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingCards;







// import React from 'react';
// import {Check, X} from 'lucide-react';
// import {motion} from "framer-motion";

// const PricingCards = () => {
//   const pricingTiers = [
//     {
//       name: "Pay as you go",
//       price: "$0",
//       period: "to start",
//       description: "Get your first 3 interviews free, then pay per use.",
//       recommended: false,
//       ctaText: "Start Free",
//       features: [
//         { name: "First 3 interviews completely free", included: true },
//         { name: "$1 per interview for over-the-shelf interviews (first 30 days)", included: true },
//         { name: "$5 per interview for over-the-shelf interviews  or $7 per interview for your own curated interviews (after 30 days)", included: true },
//       ]
//     },
//     {
//       name: "Monthly",
//       price: "$25",
//       period: "/month",
//       description: "50% Early bird discount (Regular $50/month).",
//       recommended: true,
//       ctaText: "Subscribe Now",
//       features: [
//         { name: "All Pay as you go features included", included: true },
//         { name: "Unlimited access to AI-based resume review", included: true },
//         { name: "Review one report monthly with an executive coach.", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     },
//     {
//       name: "Enterprise",
//       price: "💎",
//       period: "",
//       description: "For pricing details, contact sales.",
//       recommended: false,
//       ctaText: "Contact Sales",
//       features: [
//         { name: "Unlimited interviews", included: true },
//         { name: "Unlimited access to AI-based resume review", included: true },
//         { name: "Monthly executive coach session for all candidates", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     }
//   ];

//   return (
//     <div className="sm:min-h-screen p-4 sm:p-8" id="pricing">
//       <div className="max-w-6xl mx-auto">
//         <motion.div
//           initial={{opacity: 0, y: 20}}
//           animate={{opacity: 1, y: 0}}
//           className="max-w-7xl mx-auto mb-4 sm:mb-8"
//         >
//           <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-indigo-950 flex items-center">
//             Simple, transparent pricing
//             <span className="ml-2 sm:ml-6 h-1 flex-grow bg-purple-400"/>
//           </h1>
//         </motion.div>
        
//         <div className="grid md:grid-cols-3 gap-4 sm:gap-8">
//           {pricingTiers.map((tier) => (
//             <div
//               key={tier.name}
//               className="relative rounded-xl sm:rounded-2xl p-4 sm:p-6 md:p-8 flex flex-col bg-gradient-to-br from-indigo-100 to-purple-100 transform hover:-translate-y-2 sm:hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-md sm:shadow-xl"
//             >
//               {tier.recommended && (
//                 <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
//                   <span className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm font-semibold px-4 py-1 rounded-full">
//                     Recommended
//                   </span>
//                 </div>
//               )}

//               <div className="space-y-4">
//                 <h3 className="text-2xl sm:text-3xl md:text-4xl font-bold text-indigo-950">
//                   {tier.name}
//                 </h3>
//                 <div className="flex items-baseline">
//                   <span className="text-3xl sm:text-4xl md:text-5xl font-bold text-indigo-950">
//                     {tier.price}
//                   </span>
//                   <span className="text-base sm:text-lg ml-2 text-indigo-700">
//                     {tier.period}
//                   </span>
//                 </div>
//                 <p className="text-lg sm:text-xl text-indigo-800">
//                   {tier.description}
//                 </p>
//               </div>

//               <div className="mt-8 space-y-4 flex-grow">
//                 {tier.features.map((feature, featureIndex) => (
//                   <div 
//                     key={featureIndex}
//                     className="flex items-center space-x-3"
//                   >
//                     {feature.included ? (
//                       <Check className="w-5 h-5 text-indigo-600 flex-shrink-0" />
//                     ) : (
//                       <X className="w-5 h-5 text-indigo-300 flex-shrink-0" />
//                     )}
//                     <span className={`
//                       ${feature.included 
//                         ? 'text-indigo-800' 
//                         : 'text-indigo-400'
//                       }
//                     `}>
//                       {feature.name}
//                     </span>
//                   </div>
//                 ))}
//               </div>

//               <button className="w-full mt-8 px-4 py-3 rounded-lg font-semibold transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700">
//                 {tier.ctaText}
//               </button>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingCards;






// import React from 'react';
// import {Check, X} from 'lucide-react';

// const PricingCards = () => {
//   const pricingTiers = [
//     {
//       name: "Pay as you go",
//       price: 0,
//       period: "to start",
//       description: "Get your first 3 interviews free, then pay per use",
//       recommended: false,
//       ctaText: "Start Free",
//       features: [
//         { name: "First 3 interviews completely free", included: true },
//         { name: "$1 per interview for over-the-shelf interviews (first 30 days)", included: true },
//         { name: "$5 per interview for over-the-shelf interviews  or $7 per interview for your own curated interviews (after 30 days)", included: true },
//         // { name: "$7 per interview for curated interviews (bring your own JD)", included: true },
//       ]
//     },
//     {
//       name: "Monthly Subscription",
//       price: 25,
//       period: "month",
//       description: "50% Early bird discount (Regular $50/month)",
//       recommended: true,
//       ctaText: "Subscribe Now",
//       features: [
//         { name: "All Pay as you go features included", included: true },
//         { name: "Unlimited access to AI-based resume review", included: true },
//         { name: "Review one report monthly with an executive coach.", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     },
//     {
//       name: "Enterprise",
//       price: 500,
//       period: "month",
//       description: "Billed annually. 50% early bird discount (Regular $1,000/month)",
//       recommended: false,
//       ctaText: "Contact Sales",
//       features: [
//         { name: "Unlimited interviews", included: true },
//         { name: "Unlimited access to AI-based resume review", included: true },
//         { name: "Monthly executive coach session for all candidates", included: true },
//         { name: "Identify strengths and growth areas", included: true }
//       ]
//     }
//   ];

//   return (
//     <div className="min-h-screen p-8" id='pricing'>
//       <div className="max-w-6xl mx-auto">
//         <div className="text-center mb-16">
//           <h2 className="text-4xl font-bold text-indigo-950 mb-4">Simple, transparent pricing</h2>
//           <p className="text-xl text-indigo-900">Start with 3 free interviews</p>
//         </div>
        
//         <div className="grid md:grid-cols-3 gap-8">
//           {pricingTiers.map((tier) => (
//             <div
//               key={tier.name}
//               className="relative rounded-2xl p-8 flex flex-col bg-gradient-to-br from-indigo-100 to-purple-100 transform hover:-translate-y-3 transition-all duration-300 ease-in-out shadow-xl"
              
//                 // className={`relative rounded-2xl p-8 flex flex-col
//                 //   ${tier.recommended 
//                 //     ? 'bg-gradient-to-br from-indigo-100 to-purple-100 transform hover:-translate-y-3' 
//                 //     : 'bg-white/90 backdrop-blur-lg hover:bg-white transform hover:-translate-y-2'} 
//                 //   transition-all duration-300 ease-in-out shadow-xl`}

//             >
//               {tier.recommended && (
//                 <div className="absolute -top-4 left-1/2 transform -translate-x-1/2">
//                   <span className="bg-gradient-to-r from-indigo-600 to-purple-600 text-white text-sm font-semibold px-4 py-1 rounded-full">
//                     Recommended
//                   </span>
//                 </div>
//               )}

//               <div className="space-y-4">
//                 <h3 className="text-2xl font-bold text-indigo-950">
//                   {tier.name}
//                 </h3>
//                 <div className="flex items-baseline">
//                   <span className="text-5xl font-bold text-indigo-950">
//                     ${tier.price}
//                   </span>
//                   <span className="text-lg ml-2 text-indigo-700">
//                     /{tier.period}
//                   </span>
//                 </div>
//                 <p className="text-indigo-800">
//                   {tier.description}
//                 </p>
//               </div>

//               <div className="mt-8 space-y-4 flex-grow">
//                 {tier.features.map((feature, featureIndex) => (
//                   <div 
//                     key={featureIndex}
//                     className="flex items-center space-x-3"
//                   >
//                     {feature.included ? (
//                       <Check className="w-5 h-5 text-indigo-600 flex-shrink-0" />
//                     ) : (
//                       <X className="w-5 h-5 text-indigo-300 flex-shrink-0" />
//                     )}
//                     <span className={`
//                       ${feature.included 
//                         ? 'text-indigo-800' 
//                         : 'text-indigo-400'
//                       }
//                     `}>
//                       {feature.name}
//                     </span>
//                   </div>
//                 ))}
//               </div>

//               <button
//   className="w-full mt-8 px-4 py-3 rounded-lg font-semibold transform transition-transform duration-200 hover:scale-105 bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700"
// >
//   {tier.ctaText}
// </button>


//               {/* <button
//                 className={`w-full mt-8 px-4 py-3 rounded-lg font-semibold transform transition-transform duration-200 hover:scale-105
//                   ${tier.recommended
//                     ? 'bg-gradient-to-r from-indigo-600 to-purple-600 text-white hover:from-indigo-700 hover:to-purple-700'
//                     : 'bg-indigo-600 text-white hover:bg-indigo-700'
//                   }`}
//               >
                
//                 {tier.ctaText}
//               </button> */}
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PricingCards;

